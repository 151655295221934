import React, { useEffect, useState } from "react"
import { SHA1 } from "crypto-js"
import { isMobile } from "react-device-detect"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import LinearProgress from "@mui/material/LinearProgress"
import DialogContent from "@mui/material/DialogContent"

import "./App.css"
import { init } from "./connection"

export enum EViewState {
  loading = "loading",
  error = "error",
  expired = "expired",
  continue = "continue",
}

function App() {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("")
  const [useSmallLoader, setUseSmallLoader] = useState(true)
  const [viewState, setViewState] = useState<{
    state: EViewState
    data?: string
  }>({ state: EViewState.loading })

  useEffect(() => {
    console.log(`Version: ${process.env.REACT_APP_VERSION}`)
    run()
    getBackgroundImage()
  }, [])

  useEffect(() => {
    console.log("viewState", viewState)
  }, [viewState])

  const run = async () => {
    const res = await init()
    setViewState(res)
  }

  const getBackgroundImage = async () => {
    const res = await fetch(`${process.env.PUBLIC_URL}/brndtoom.json`)
    const mapping = await res.json()

    const urlParts = window.location.pathname.split("/").slice(1)
    const brand = urlParts[0]
    const fileName = isMobile ? "bgMobile" : "bgDesktop"

    if (brand) {
      const orgIdKey = `B${SHA1(brand).toString()}`
      const orgId = mapping[orgIdKey as keyof typeof mapping]

      const env =
        process.env.REACT_APP_ENV === "dev"
          ? "/dev"
          : process.env.REACT_APP_ENV === "stg"
          ? "/stg"
          : ""

      const cacheBuster = `?cb=${new Date().getTime()}`
      const url = `https://res.cloudinary.com/dynamofyi/image/upload/f_auto,q_auto${env}/organizations/${orgId}/d10x.co/default/${fileName}${cacheBuster}`
      setBackgroundImageUrl(url)
      const imageRes = await fetch(url, { method: "HEAD" })
      console.log("imageRes", imageRes)
      if (imageRes.ok) {
        setUseSmallLoader(true)
      } else {
        setUseSmallLoader(false)
      }
    }
  }

  // const renderState = () => {
  //   if (EViewState.continue) {
  //     return (
  //       <div
  //         style={{
  //           flex: 1,
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           paddingBottom: "10%",
  //           padding: "10%",
  //         }}
  //       >
  //         <Button
  //           variant="contained"
  //           onClick={() => {
  //             clearHistoryAndNavigate(viewState.data!)
  //           }}
  //         >
  //           continue
  //         </Button>
  //       </div>
  //     )
  //   }

  //   if (EViewState.loading && !useSmallLoader) {
  //     return (
  //       <div
  //         style={{
  //           flex: 1,
  //           display: "flex",
  //           justifyContent: useSmallLoader ? "flex-end" : "center",
  //           alignItems: useSmallLoader ? "flex-start" : "center",
  //           paddingBottom: "10%",
  //           padding: "10%",
  //         }}
  //       >
  //         <Avatar
  //           style={{
  //             width: "120px",
  //             height: "120px",
  //           }}
  //           src={`${process.env.PUBLIC_URL}/images/new_external_link_loader.svg`}
  //         />
  //       </div>
  //     )
  //   }
  // }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: 20,
        flex: 1,
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {renderState()}
      </div> */}
      <div style={{ flex: 1 }} />
      {useSmallLoader && viewState.state === EViewState.loading && (
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            marginBottom: "10px",
          }}
        >
          <LinearProgress style={{ backgroundColor: "#fff" }} />
        </div>
      )}
      <div style={{ textAlign: "center" }}>
        <a
          href="https://www.dynamo.fyi"
          target="_blank"
          style={{
            textShadow: "1px 1px 2px #000",
            textDecoration: "none",
            fontSize: "15px",
            fontFamily: "Avenir",
            margin: "5px 0 0 0",
            color: "#fff",
          }}
          rel="noreferrer"
        >
          Powered by Dynamo10x
        </a>
      </div>
      <Dialog
        open={viewState.state === EViewState.error}
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <DialogContent
          style={{
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{
              width: "60px",
              height: "60px",
            }}
            src={`${process.env.PUBLIC_URL}/images/error.svg`}
          />
          <Typography
            variant="h6"
            style={{
              fontFamily: "Avenir",
              margin: "30px 0",
              textAlign: "center",
            }}
          >
            Oops, something is wrong
          </Typography>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#000",
              borderRadius: 10,
              textTransform: "none",
            }}
            onClick={() => {
              setViewState({ state: EViewState.loading })
              run()
            }}
          >
            Try Again
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={viewState.state === EViewState.expired}
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <DialogContent
          style={{
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            variant="square"
            style={{
              width: "74px",
              height: "70px",
            }}
            src={`${process.env.PUBLIC_URL}/images/expired.svg`}
          />
          <Typography
            variant="h6"
            style={{
              fontFamily: "Avenir",
              margin: "30px 0",
              textAlign: "center",
            }}
          >
            Link expired
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default App
